export const routes = {
  homeUrl: "/",
  // ESPACE CANDIDAT
  candidatUrl: {
    index: "/travail-extra-cdi-interim",
    hidfLyon: "travailler-a-lyon",
    lpJo: "emploi-jeux-olympiques-2024",
    lpInterim: "interim-restauration-paris",
  },
  // ESPACE RECRUTEUR
  recruteurUrl: {
    index: "/recrutement",
    lpEtabLyon: "restauration-vente-lyon",
    bu: {
      cafeRestaurant: "renfort-extra-restauration",
      hotellerie: "renfort-extra-hotellerie",
      restaurationCollective: "renfort-restauration-collective",
      traiteurEvenementiel: "renfort-extra-traiteur-evenementiel",
      commerceDeBouche: "renfort-extra-commerce",
      logistiqueVente: "renfort-extra-logistique-vente",
    },
  },
  // BLOG
  blogHomeUrl: "/blog",
  // FAQ
  faqUrl: {
    home: "/faq",
    candidats: "/faq/candidats",
    recruteurs: "/faq/recruteurs",
  },
  // PARTENAIRES
  partnersUrl: {
    index: "/nos-partenaires",
    candidats: "/nos-partenaires/candidats",
    recruteurs: "/nos-partenaires/recruteurs",
  },
  // MEDIA PRESSE
  mediaPressUrl: "/media-presse",
  // SIMULATEUR CA AE
  simulatorCaAe: "/simulation-revenus-auto-entrepreneur",
  // JOBS
  jobsUrl: {
    index: "https://jobs.extracadabra.com/",
    paris: "https://jobs.extracadabra.com/paris",
    lyon: "https://jobs.extracadabra.com/lyon",
    extra: "https://jobs.extracadabra.com/missions-extra",
    ae: "https://jobs.extracadabra.com/missions-extra?c=extra",
    cdi: "https://jobs.extracadabra.com/recherche?c=cdi",
    saisonnier: "https://jobs.extracadabra.com/recherche?c=saisonnier",
    interim: "https://jobs.extracadabra.com/recherche?c=interim",
    hotel: "https://jobs.extracadabra.com/hotellerie-restauration",
    logistic: "https://jobs.extracadabra.com/vente-logistique",
    chefDeCuisine: "https://jobs.extracadabra.com/chef-de-cuisine",
    employePolyvalent: "https://jobs.extracadabra.com/employe-polyvalent",
    serveur: "https://jobs.extracadabra.com/emploi-serveur",
    chefDeRang: "https://jobs.extracadabra.com/chef-de-rang",
    patissier: "https://jobs.extracadabra.com/patissier",
    barman: "https://jobs.extracadabra.com/poste-barman",
    maitreDhotel: "https://jobs.extracadabra.com/maitre-d-hotel",
    runner: "https://jobs.extracadabra.com/runner",
  },
  // ETABLISSEMENT WEB APP
  etabWebAppUrl: {
    baseUrl: "https://etablissement.extracadabra.com/",
    landing: "#/auth/landing",
    signup: "#/auth/signup",
    login: "#/auth/login",
  },

  // CANDIDAT WEB APP
  candidatWebAppUrl: {
    baseUrl: "https://candidat.extracadabra.com/",
    signup: "#/creer-un-compte",
    login: "#/se-connecter",
  },
  cgu: "/cgu",
  confidentiality: "/confidentiality",
  faqByTypeUrl: (type: "candidats" | "recruteurs") => `/faq/${type}`,
  faqItem: (type: "candidats" | "recruteurs", slug: string) => `/faq/${type}/${slug}`,
};
