import { default as _91pageNumber_93e8OuOGTAHdMeta } from "/var/www/showcase/packages/client/pages/blog/[tag]/[pageNumber].vue?macro=true";
import { default as indexBDWO0By4olMeta } from "/var/www/showcase/packages/client/pages/blog/[tag]/index.vue?macro=true";
import { default as _91slug_93fprbBMfZKPMeta } from "/var/www/showcase/packages/client/pages/blog/articles/[slug].vue?macro=true";
import { default as indexlzzeowQG9KMeta } from "/var/www/showcase/packages/client/pages/blog/index.vue?macro=true";
import { default as cguDu8sfEkPWYMeta } from "/var/www/showcase/packages/client/pages/cgu.vue?macro=true";
import { default as confidentialityddzoehyT0oMeta } from "/var/www/showcase/packages/client/pages/confidentiality.vue?macro=true";
import { default as _91slug_93NI525saIb7Meta } from "/var/www/showcase/packages/client/pages/faq/[type]/[slug].vue?macro=true";
import { default as indexLUJyowJzLdMeta } from "/var/www/showcase/packages/client/pages/faq/[type]/index.vue?macro=true";
import { default as indexgnNSEiBfaYMeta } from "/var/www/showcase/packages/client/pages/faq/index.vue?macro=true";
import { default as index6FIdv79BYwMeta } from "/var/www/showcase/packages/client/pages/index.vue?macro=true";
import { default as indexqyaoeIalq8Meta } from "/var/www/showcase/packages/client/pages/media-presse/index.vue?macro=true";
import { default as indexXo254l3vvjMeta } from "/var/www/showcase/packages/client/pages/nos-partenaires/candidats/index.vue?macro=true";
import { default as index280mGaIkUuMeta } from "/var/www/showcase/packages/client/pages/nos-partenaires/index.vue?macro=true";
import { default as indexyZZbp7iaoBMeta } from "/var/www/showcase/packages/client/pages/nos-partenaires/recruteurs/index.vue?macro=true";
import { default as indexaDbIrVPR1yMeta } from "/var/www/showcase/packages/client/pages/recrutement/index.vue?macro=true";
import { default as lp_45metromdD9cVbR2KMeta } from "/var/www/showcase/packages/client/pages/recrutement/lp-metro.vue?macro=true";
import { default as indexbs6wwjP7W8Meta } from "/var/www/showcase/packages/client/pages/recrutement/renfort-extra-commerce/index.vue?macro=true";
import { default as index5DlL96JhqNMeta } from "/var/www/showcase/packages/client/pages/recrutement/renfort-extra-hotellerie/index.vue?macro=true";
import { default as indexWBuynBod6yMeta } from "/var/www/showcase/packages/client/pages/recrutement/renfort-extra-logistique-vente/index.vue?macro=true";
import { default as indextYAAlChBqQMeta } from "/var/www/showcase/packages/client/pages/recrutement/renfort-extra-restauration/index.vue?macro=true";
import { default as indexmP9fYcBAQYMeta } from "/var/www/showcase/packages/client/pages/recrutement/renfort-extra-traiteur-evenementiel/index.vue?macro=true";
import { default as indexasOxJKE5gxMeta } from "/var/www/showcase/packages/client/pages/recrutement/renfort-restauration-collective/index.vue?macro=true";
import { default as indexYsSBUXfMC5Meta } from "/var/www/showcase/packages/client/pages/recrutement/restauration-vente-lyon/index.vue?macro=true";
import { default as indexfhZewwgQbjMeta } from "/var/www/showcase/packages/client/pages/simulation-revenus-auto-entrepreneur/index.vue?macro=true";
import { default as emploi_45jeux_45olympiques_4520248GN4eQoJfbMeta } from "/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/emploi-jeux-olympiques-2024.vue?macro=true";
import { default as indexV0pzifC5JWMeta } from "/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/index.vue?macro=true";
import { default as interim_45restauration_45parisQ0WTxh1ENgMeta } from "/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/interim-restauration-paris.vue?macro=true";
import { default as travailler_45a_45lyonNfgjcQLy2kMeta } from "/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/travailler-a-lyon.vue?macro=true";
export default [
  {
    name: _91pageNumber_93e8OuOGTAHdMeta?.name ?? "blog-tag-pageNumber",
    path: _91pageNumber_93e8OuOGTAHdMeta?.path ?? "/blog/:tag()/:pageNumber()",
    meta: _91pageNumber_93e8OuOGTAHdMeta || {},
    alias: _91pageNumber_93e8OuOGTAHdMeta?.alias || [],
    redirect: _91pageNumber_93e8OuOGTAHdMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/blog/[tag]/[pageNumber].vue").then(m => m.default || m)
  },
  {
    name: indexBDWO0By4olMeta?.name ?? "blog-tag",
    path: indexBDWO0By4olMeta?.path ?? "/blog/:tag()",
    meta: indexBDWO0By4olMeta || {},
    alias: indexBDWO0By4olMeta?.alias || [],
    redirect: indexBDWO0By4olMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/blog/[tag]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fprbBMfZKPMeta?.name ?? "blog-articles-slug",
    path: _91slug_93fprbBMfZKPMeta?.path ?? "/blog/articles/:slug()",
    meta: _91slug_93fprbBMfZKPMeta || {},
    alias: _91slug_93fprbBMfZKPMeta?.alias || [],
    redirect: _91slug_93fprbBMfZKPMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/blog/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlzzeowQG9KMeta?.name ?? "blog",
    path: indexlzzeowQG9KMeta?.path ?? "/blog",
    meta: indexlzzeowQG9KMeta || {},
    alias: indexlzzeowQG9KMeta?.alias || [],
    redirect: indexlzzeowQG9KMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: cguDu8sfEkPWYMeta?.name ?? "cgu",
    path: cguDu8sfEkPWYMeta?.path ?? "/cgu",
    meta: cguDu8sfEkPWYMeta || {},
    alias: cguDu8sfEkPWYMeta?.alias || [],
    redirect: cguDu8sfEkPWYMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/cgu.vue").then(m => m.default || m)
  },
  {
    name: confidentialityddzoehyT0oMeta?.name ?? "confidentiality",
    path: confidentialityddzoehyT0oMeta?.path ?? "/confidentiality",
    meta: confidentialityddzoehyT0oMeta || {},
    alias: confidentialityddzoehyT0oMeta?.alias || [],
    redirect: confidentialityddzoehyT0oMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/confidentiality.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NI525saIb7Meta?.name ?? "faq-type-slug",
    path: _91slug_93NI525saIb7Meta?.path ?? "/faq/:type()/:slug()",
    meta: _91slug_93NI525saIb7Meta || {},
    alias: _91slug_93NI525saIb7Meta?.alias || [],
    redirect: _91slug_93NI525saIb7Meta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/faq/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLUJyowJzLdMeta?.name ?? "faq-type",
    path: indexLUJyowJzLdMeta?.path ?? "/faq/:type()",
    meta: indexLUJyowJzLdMeta || {},
    alias: indexLUJyowJzLdMeta?.alias || [],
    redirect: indexLUJyowJzLdMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/faq/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgnNSEiBfaYMeta?.name ?? "faq",
    path: indexgnNSEiBfaYMeta?.path ?? "/faq",
    meta: indexgnNSEiBfaYMeta || {},
    alias: indexgnNSEiBfaYMeta?.alias || [],
    redirect: indexgnNSEiBfaYMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index6FIdv79BYwMeta?.name ?? "index",
    path: index6FIdv79BYwMeta?.path ?? "/",
    meta: index6FIdv79BYwMeta || {},
    alias: index6FIdv79BYwMeta?.alias || [],
    redirect: index6FIdv79BYwMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqyaoeIalq8Meta?.name ?? "media-presse",
    path: indexqyaoeIalq8Meta?.path ?? "/media-presse",
    meta: indexqyaoeIalq8Meta || {},
    alias: indexqyaoeIalq8Meta?.alias || [],
    redirect: indexqyaoeIalq8Meta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/media-presse/index.vue").then(m => m.default || m)
  },
  {
    name: indexXo254l3vvjMeta?.name ?? "nos-partenaires-candidats",
    path: indexXo254l3vvjMeta?.path ?? "/nos-partenaires/candidats",
    meta: indexXo254l3vvjMeta || {},
    alias: indexXo254l3vvjMeta?.alias || [],
    redirect: indexXo254l3vvjMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/nos-partenaires/candidats/index.vue").then(m => m.default || m)
  },
  {
    name: index280mGaIkUuMeta?.name ?? "nos-partenaires",
    path: index280mGaIkUuMeta?.path ?? "/nos-partenaires",
    meta: index280mGaIkUuMeta || {},
    alias: index280mGaIkUuMeta?.alias || [],
    redirect: index280mGaIkUuMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/nos-partenaires/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZZbp7iaoBMeta?.name ?? "nos-partenaires-recruteurs",
    path: indexyZZbp7iaoBMeta?.path ?? "/nos-partenaires/recruteurs",
    meta: indexyZZbp7iaoBMeta || {},
    alias: indexyZZbp7iaoBMeta?.alias || [],
    redirect: indexyZZbp7iaoBMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/nos-partenaires/recruteurs/index.vue").then(m => m.default || m)
  },
  {
    name: indexaDbIrVPR1yMeta?.name ?? "recrutement",
    path: indexaDbIrVPR1yMeta?.path ?? "/recrutement",
    meta: indexaDbIrVPR1yMeta || {},
    alias: indexaDbIrVPR1yMeta?.alias || [],
    redirect: indexaDbIrVPR1yMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/index.vue").then(m => m.default || m)
  },
  {
    name: lp_45metromdD9cVbR2KMeta?.name ?? "recrutement-lp-metro",
    path: lp_45metromdD9cVbR2KMeta?.path ?? "/recrutement/lp-metro",
    meta: lp_45metromdD9cVbR2KMeta || {},
    alias: lp_45metromdD9cVbR2KMeta?.alias || [],
    redirect: lp_45metromdD9cVbR2KMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/lp-metro.vue").then(m => m.default || m)
  },
  {
    name: indexbs6wwjP7W8Meta?.name ?? "recrutement-renfort-extra-commerce",
    path: indexbs6wwjP7W8Meta?.path ?? "/recrutement/renfort-extra-commerce",
    meta: indexbs6wwjP7W8Meta || {},
    alias: indexbs6wwjP7W8Meta?.alias || [],
    redirect: indexbs6wwjP7W8Meta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/renfort-extra-commerce/index.vue").then(m => m.default || m)
  },
  {
    name: index5DlL96JhqNMeta?.name ?? "recrutement-renfort-extra-hotellerie",
    path: index5DlL96JhqNMeta?.path ?? "/recrutement/renfort-extra-hotellerie",
    meta: index5DlL96JhqNMeta || {},
    alias: index5DlL96JhqNMeta?.alias || [],
    redirect: index5DlL96JhqNMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/renfort-extra-hotellerie/index.vue").then(m => m.default || m)
  },
  {
    name: indexWBuynBod6yMeta?.name ?? "recrutement-renfort-extra-logistique-vente",
    path: indexWBuynBod6yMeta?.path ?? "/recrutement/renfort-extra-logistique-vente",
    meta: indexWBuynBod6yMeta || {},
    alias: indexWBuynBod6yMeta?.alias || [],
    redirect: indexWBuynBod6yMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/renfort-extra-logistique-vente/index.vue").then(m => m.default || m)
  },
  {
    name: indextYAAlChBqQMeta?.name ?? "recrutement-renfort-extra-restauration",
    path: indextYAAlChBqQMeta?.path ?? "/recrutement/renfort-extra-restauration",
    meta: indextYAAlChBqQMeta || {},
    alias: indextYAAlChBqQMeta?.alias || [],
    redirect: indextYAAlChBqQMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/renfort-extra-restauration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmP9fYcBAQYMeta?.name ?? "recrutement-renfort-extra-traiteur-evenementiel",
    path: indexmP9fYcBAQYMeta?.path ?? "/recrutement/renfort-extra-traiteur-evenementiel",
    meta: indexmP9fYcBAQYMeta || {},
    alias: indexmP9fYcBAQYMeta?.alias || [],
    redirect: indexmP9fYcBAQYMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/renfort-extra-traiteur-evenementiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexasOxJKE5gxMeta?.name ?? "recrutement-renfort-restauration-collective",
    path: indexasOxJKE5gxMeta?.path ?? "/recrutement/renfort-restauration-collective",
    meta: indexasOxJKE5gxMeta || {},
    alias: indexasOxJKE5gxMeta?.alias || [],
    redirect: indexasOxJKE5gxMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/renfort-restauration-collective/index.vue").then(m => m.default || m)
  },
  {
    name: indexYsSBUXfMC5Meta?.name ?? "recrutement-restauration-vente-lyon",
    path: indexYsSBUXfMC5Meta?.path ?? "/recrutement/restauration-vente-lyon",
    meta: indexYsSBUXfMC5Meta || {},
    alias: indexYsSBUXfMC5Meta?.alias || [],
    redirect: indexYsSBUXfMC5Meta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/recrutement/restauration-vente-lyon/index.vue").then(m => m.default || m)
  },
  {
    name: indexfhZewwgQbjMeta?.name ?? "simulation-revenus-auto-entrepreneur",
    path: indexfhZewwgQbjMeta?.path ?? "/simulation-revenus-auto-entrepreneur",
    meta: indexfhZewwgQbjMeta || {},
    alias: indexfhZewwgQbjMeta?.alias || [],
    redirect: indexfhZewwgQbjMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/simulation-revenus-auto-entrepreneur/index.vue").then(m => m.default || m)
  },
  {
    name: emploi_45jeux_45olympiques_4520248GN4eQoJfbMeta?.name ?? "travail-extra-cdi-interim-emploi-jeux-olympiques-2024",
    path: emploi_45jeux_45olympiques_4520248GN4eQoJfbMeta?.path ?? "/travail-extra-cdi-interim/emploi-jeux-olympiques-2024",
    meta: emploi_45jeux_45olympiques_4520248GN4eQoJfbMeta || {},
    alias: emploi_45jeux_45olympiques_4520248GN4eQoJfbMeta?.alias || [],
    redirect: emploi_45jeux_45olympiques_4520248GN4eQoJfbMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/emploi-jeux-olympiques-2024.vue").then(m => m.default || m)
  },
  {
    name: indexV0pzifC5JWMeta?.name ?? "travail-extra-cdi-interim",
    path: indexV0pzifC5JWMeta?.path ?? "/travail-extra-cdi-interim",
    meta: indexV0pzifC5JWMeta || {},
    alias: indexV0pzifC5JWMeta?.alias || [],
    redirect: indexV0pzifC5JWMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/index.vue").then(m => m.default || m)
  },
  {
    name: interim_45restauration_45parisQ0WTxh1ENgMeta?.name ?? "travail-extra-cdi-interim-interim-restauration-paris",
    path: interim_45restauration_45parisQ0WTxh1ENgMeta?.path ?? "/travail-extra-cdi-interim/interim-restauration-paris",
    meta: interim_45restauration_45parisQ0WTxh1ENgMeta || {},
    alias: interim_45restauration_45parisQ0WTxh1ENgMeta?.alias || [],
    redirect: interim_45restauration_45parisQ0WTxh1ENgMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/interim-restauration-paris.vue").then(m => m.default || m)
  },
  {
    name: travailler_45a_45lyonNfgjcQLy2kMeta?.name ?? "travail-extra-cdi-interim-travailler-a-lyon",
    path: travailler_45a_45lyonNfgjcQLy2kMeta?.path ?? "/travail-extra-cdi-interim/travailler-a-lyon",
    meta: travailler_45a_45lyonNfgjcQLy2kMeta || {},
    alias: travailler_45a_45lyonNfgjcQLy2kMeta?.alias || [],
    redirect: travailler_45a_45lyonNfgjcQLy2kMeta?.redirect || undefined,
    component: () => import("/var/www/showcase/packages/client/pages/travail-extra-cdi-interim/travailler-a-lyon.vue").then(m => m.default || m)
  }
]