import type { FetchError } from "ofetch";
import type { AsyncDataOptions } from "nuxt/app";
import HttpFactory from "../../factory";
import type { APIParams, APIResponse } from "~/models/Api";
import type { QualificationsCandidateCAGroup } from "~/models/Qualifications";
class QualificationGroupsModule extends HttpFactory {
  private resourcePath = "/api/qualifications-groups";

  async list<T = APIResponse<QualificationsCandidateCAGroup[]>, R = T>(
    { filters, pagination, populate, sort, fields, locale }: APIParams = {
      filters: {},
      populate: {},
    },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(
      "qualificationGroups",
      () => {
        const params = {
          populate: {
            groups: true,
            ...populate,
          },
          filters,
          pagination,
          sort,
          fields,
          locale,
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }
}

export default QualificationGroupsModule;
