export const simulatorCaAe = {
  slug: "auto-entrepreneur",
  title: "Auto-entrepreneur, combien pourrais-tu gagner en tant que",
  title_flip_list:
    "<li><span>Chef de rang</span> ?</li> <li><span>Serveur</span> ?</li> <li><span>Barman</span> ?</li> <li><span>Runner</span> ?</li><li><span>Réceptionniste</span> ?</li>",
  step: {
    group: {
      title: "Choisis ton domaine",
    },
    qualif: {
      title: "Choisis ton métier",
      placeholder: "Sélectionne ton métier",
    },
    time: {
      title: "Choisis le nombre d’heures",
      hour: "h",
      workPerWeek: "travaillées par semaine",
    },
  },
  result: {
    title: {
      part1: "Si tu travailles en tant que <br />",
      part2: "heure(s) / semaine",
      part3: "tu pourras gagner entre :",
    },
    rate: {
      and: "et",
      euroPerMonth: "euros / mois",
      socialContribution: "Estimation de tes côtisations sociales*",
      between: "Entre",
      or: "ou",
    },
  },
  cta: {
    submit: "Lancer la simulation",
    subscribe: "M’inscrire gratuitement",
    newSimulator: "Faire une nouvelle simulation",
  },
  footer: {
    legals: `*Les côtisations sociales des auto-entrepreneurs en restauration représentent un
      pourcentage de leur chiffre d’affaires. Au 1er juillet 2024, elles s’élèvent à 23%
      couvrant la santé, la retraite et les allocations familiales.`,
  },
};
