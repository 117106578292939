import type { AsyncDataOptions } from "nuxt/app";
import type { FetchError } from "ofetch";
import HttpFactory from "../../factory";
import type { APIResponse } from "~/models/Api";
import type {
  QualificationsCandidateCARate,
  QualificationsCandidateCA,
} from "~/models/Qualifications";

class QualificationsModule extends HttpFactory {
  private resourcePath = "/api/qualifications";

  async getByGroup(
    {
      group,
    }: {
      group?: Ref<string[]> | string[];
    },
    asyncDataOptions?: AsyncDataOptions<APIResponse<QualificationsCandidateCA[]>>,
  ) {
    return await useAsyncData<APIResponse<QualificationsCandidateCA[]>>(
      "qualificationsByGroup",
      () => {
        const params = {
          populate: {
            qualifications_groups: true,
          },
          filters: {
            qualifications_groups: {
              type: {
                $in: isRef(group) ? group.value : group,
              },
            },
          },
        };

        return this.call({
          method: "GET",
          url: this.resourcePath,
          fetchOptions: {
            params,
          },
        });
      },
      asyncDataOptions,
    );
  }

  async getQualificationRate<T = QualificationsCandidateCARate, R = T>(
    { id }: { id: string | Ref<string> },
    asyncDataOptions?: AsyncDataOptions<T, R>,
  ) {
    return await useAsyncData<T, FetchError, R>(() => {
      const url = `${this.resourcePath}/${id}`;
      const params = {
        populate: {
          candidat_ca_ae: true,
        },
      };
      return this.call({
        method: "GET",
        url,
        fetchOptions: {
          params,
        },
      });
    }, asyncDataOptions);
  }
}

export default QualificationsModule;
